.isCurrent {
  a {
    color: rgb(83, 89, 234) !important;
    border-bottom: 5px solid rgb(83, 89, 234);
  }
}
.currency {
  font-size: 27px !important;
  align-items: center !important;
  font-weight: 700;
  color: #000;
}
.flex-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
flex-wrap: wrap;
}
.locationtype {
  display: flex;
  align-self: flex-start;
  justify-content: space-between;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #ddd;
  padding-left: 15px;
  padding-right: 15px;
}
.bgcolor {
  background-color: rgba(248, 249, 251, 1) !important;
  padding-top: 73px;
}
.propertyDetail {
  .inner {
    display: flex;
    align-items: center;
  }
  .left-side {
    width: 65%;
    position: relative;
    .overlapdata {
      background-color: black;
      opacity: 0.3;
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
    }
    img {
      width: 100%;
    }
  }
}
.lefsideindersub {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  padding: 28px;
}
.bottom-section {
  position: absolute;
  bottom: 10px;
  left: 20px;
  font-size: 0.875rem !important;
  line-height: 1.25rem !important;
  text-align: center !important;
  background-color: white;
  padding-top: 0.35rem !important;
  padding-bottom: 0.35rem !important;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
  border-radius: 0.5rem;
  color: #000;
  cursor: pointer;
  font-weight: 500;
  svg {
    margin-right: 10px;
  }
}
.office-dash {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  z-index: 1;
  /* left: 20px; */
  width: 100%;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 5px;
}
.label-box {
  display: flex;
  align-items: center;
}
.office {
  font-size: 0.875rem !important;
  line-height: 1.25rem !important;
  text-align: center !important;
  background-color: white;
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
  border-radius: 0.5rem;
  cursor: pointer;
  color: #000;
  margin-right: 10px;
  font-weight: 500;
}
.Preleased {
  height: 30px;
  font-size: 0.875rem !important;
  line-height: 1.25rem !important;
  text-align: center !important;
  background-color: white;
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
  border-radius: 0.5rem;
  color: #000;
  cursor: pointer;
  font-weight: 500;
}
.rightside {
  width: 35%;
  margin-left: 1rem;
  .line {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .location {
    font-size: 0.875rem !important;
    line-height: 1.25rem !important;
    color: rgb(151, 157, 168) !important;
    align-items: center !important;
    svg {
      margin-right: 10px;
    }
  }
  .reset {
    text-align: right !important;
    color: rgba(156, 163, 175, 1) !important;
    margin-top: 0.75rem !important;
    font-size: 14px;
    text-decoration: underline;

    font-weight: 600 !important;
  }
  .purchase {
    color: rgba(107, 114, 128, 1) !important;

    font-weight: 500 !important;

    font-size: 1rem !important;
    line-height: 1.5rem !important;
  }
  .purchaseMoney {
    color: rgba(83, 89, 234, 1);

    font-weight: 600 !important;

    font-size: 1.125rem !important;
  }
  .avgprice {
    color: rgba(107, 114, 128, 1) !important;

    font-weight: 400 !important;

    font-size: 0.875rem !important;
    line-height: 1.25rem !important;

    align-items: center !important;

    display: flex !important;
    svg {
      margin-left: 10px;
      margin-top: 0px !important;
    }
  }
  .imgSvg {
    padding: 1rem !important;
    background-color: rgba(210, 249, 222, 1) !important;
    width: 68px;

    border-radius: 0.5rem !important;
  }
  .labelpriceAvgPrice {
    padding: 1rem;
    display: flex;
  }
  .interested {
    width: 48%;
    border-radius: 10px;
    color: #fff !important;
    font-weight: 500 !important;
    background-color: rgba(83, 89, 234, 1) !important;
    font-size: 0.875rem !important;
    line-height: 1.25rem !important;
    text-align: center !important;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .whatsup {
    width: 48%;

    border: 1px solid rgba(37, 211, 102, 1);
    border-radius: 10px;
    color: rgba(37, 211, 102, 1) !important;

    font-weight: 500 !important;
    background-color: #25d36624 !important;
    font-size: 0.875rem !important;
    line-height: 1.25rem !important;
    text-align: center !important;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      margin-right: 10px;
    }
  }
  .left-avg {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .labelbottompricelist {
    border: 1px solid #ddd;
    display: flex;
    align-items: center;
    border-radius: 15px;
    padding: 12px;
  }
  .avgBellowprice {
    color: #000;
    margin-top: 5px;
    font-weight: 600 !important;

    font-size: 1.125rem !important;
  }
  .labelcheck {
    display: flex;
    margin: 0;
    padding: 6px;
    list-style: none;
    background-color: #f3f3f3;
    border-radius: 10px;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-top: 0.375rem !important;
    padding-bottom: 0.375rem !important;
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: rgba(243, 244, 246, 1) !important;

    border-radius: 0.5rem !important;
  }

  .labeltext {
    font-weight: 700 !important;
    font-size: 0.875rem !important;
    line-height: 1.25rem !important;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 3rem;
    border-radius: 10px;
  }

  .rio {
    font-size: 14px;
    border: 1px solid #e5e7eb;
    font-weight: 600;
    color: #000;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    border-radius: 0.5rem;
    border-radius: 0.5rem;
    width: 10rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    svg {
      margin-right: 10px;
    }
  }
}
.single-thumb .range-slider__thumb[data-lower] {
  width: 0;
  background: #000;
}
.single-thumb .range-slider__range {
  border-radius: 6px;
  background: #000 !important;
}
.single-thumb .range-slider__thumb[data-upper] {
  background: #000;
}
#range-slider-gradient .range-slider__thumb[data-lower] {
  background: #000 !important;
}

.tab-section {
  border-bottom: 1px solid #ddd;
  border-top: 1px solid #ddd;
  padding-bottom: 8px;
  padding-top: 8px;
  background-color: white;
  margin-top: 1.3rem;
  width: 100%;
  z-index: 9;
  top: 34px;
  .member-nav {
    display: flex;
    align-items: center;
    margin: 0;
  }
  ul {
    list-style-type: none;
  }
  a {
    font-size: 1rem !important;
    line-height: 1.5rem !important;
    text-decoration: none;
    font-weight: 500 !important;
    cursor: pointer;
    color: #000;
    padding-bottom: 9px;
    // border-bottom: 5px solid;
    /* margin-bottom: 55px; */
    margin-right: 29px;
  }
}
.typeyeild {
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.rentcarsoleshow {
  width: 32%;
  position: relative;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);

  margin-bottom: 2rem;
  img {
    width: 100%;
    height: 200px;
    border-radius: 10px;
  }
  h3 {
    font-size: 0.875rem !important;
    line-height: 1.25rem !important;
    color: white;
    color: white;
    position: absolute;
    /* top: 20px; */
    /* left: 22px; */
    z-index: 1;
    background: white;
    color: #000;
    border-radius: 10px;
    width: 69px;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 56px;
    right: 24px;
    svg {
      margin-right: 10px;
    }
  }
  h6 {
    color: rgba(17, 24, 39, 1) !important;

    font-weight: 700 !important;
    text-align: center !important;
    font-size: 16px !important;
  }
  h4 {
    color: rgba(75, 85, 99, 1) !important ;

    font-weight: 500 !important;

    text-align: center !important;
    font-size: 16px;
  }
}
.wImage {
  background-color: rgba(26, 177, 82, 1) !important;
  border-radius: 10px;
  border-radius: 7px;
  width: 43px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Highlights {
  display: flex;
  align-items: center;
  margin-top: 14px;
  margin-bottom: 14px;
  padding-right: 15px;
  padding-left: 15px;
  .textHighlights {
    font-size: 0.875rem !important;
    color: #000;
    margin-right: 10px;
    font-weight: 500;
  }
  .subhight {
    color: grey;
    font-size: 12px;
    margin-right: 15px;
  }
  .subhight {
    color: grey;
    font-size: 12px;
    margin-right: 15px;
  }
}

.Iinterested {
  width: 100%;
  margin-left: 15px;
  background-color: rgb(83, 89, 234) !important;
  color: white;
  height: 40px;
  display: flex;
  align-items: center;
  border-radius: 7px;
  justify-content: center;
}
.labelbutton {
  display: flex;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 15px;
  justify-content: space-between;
}

.finialcardbottom {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  background-color: white;
  padding: 20px;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.container {
  padding: 0 !important;
}
