.top-header {
  height: 100vh;
  display: flex;
  align-items: center;
  padding-left: 7rem;
  background-image: linear-gradient(
    205.49deg,
    rgba(83, 88, 226, 0.042) 16.14%,
    rgba(237, 245, 252, 0) 109.5%
  ) !important;
  padding-top: 55px;
  .left {
    height: 100%;
    width: 122rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    h1 {
      line-height: 60px;
      font-size: 3rem !important;
      text-align: left !important;
      color: rgba(17, 24, 39, 1);

      font-weight: 700;
    }

    span {
      color: rgba(83, 89, 234, 1);
    }
    p {
      font-size: 1.25rem !important;
      line-height: 1.75rem !important;

      color: rgba(107, 114, 128, 1) !important;
      font-weight: 300 !important;
      margin-top: 1rem;
    }
    label {
      background-color: rgb(83, 89, 234) !important;
      color: rgb(255, 255, 255) !important;
      font-weight: 600 !important;
      padding-left: 1rem !important;
      padding-right: 1rem !important;
      font-size: 0.875rem !important;
      line-height: 1.25rem !important;
      padding-top: 0.75rem !important;
      padding-bottom: 0.75rem !important;
      margin-top: 2rem;
      width: 12rem;
    text-align: center;
    }
  }

  .right {
    height: 100%;
    position: relative;
    width: 100%;
    padding-top: 3rem;
    img {
      height: 100%;
      width: 100%;
    }
  }
}

//metric

.Ourmetrics {
  padding-top: 1.5rem;
  background-color: rgba(250, 250, 250, 1);
  padding-bottom: 3.5rem;
  .inner {
    max-width: 1280px;
    margin: auto;
  }
  h1 {
    color: rgba(83, 89, 234, 1);
    font-size: 1.875rem !important;
    line-height: 2.25rem !important;
    font-weight: 700;
    text-align: center;
  }
  .card-inner {
    display: flex;
    align-items: center;
    margin-top: 2.5rem;
  }
  .cardsa {
    .inner {
      display: flex;

      height: 90px;
      width: 320px;
      .border {
        background-color: rgba(83, 89, 234, 1) !important;
        width: 6px;
        height: 100%;
      }
      .content {
        padding-top: 12px;
        padding-left: 20px;
        h3 {
          font-size: 1.875rem !important;
          line-height: 2.25rem !important;
          color: rgba(17, 24, 39, 1);
          font-weight: 500;
          margin: 0;
        }
        h4 {
          font-size: 0.875rem !important;
          line-height: 1.25rem !important;
          color: rgba(107, 114, 128, 1);
          font-weight: 300;
          margin: 0;
        }
      }
    }
  }
}

.propertysection {
  padding: 2rem;
  height: 29rem !important;
  border: 1px solid #ddd;
  border-radius: 10px;
  width: 21.5rem;
  position: relative;
  h1 {
    font-weight: 700 !important;
    color: rgba(17, 24, 39, 1) !important;
    font-size: 1.125rem !important;
    margin-bottom: 1rem;
    line-height: 1.75rem !important;
    margin-top: 2rem;
  }
  .property-detail {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    h2 {
      font-size: 0.875rem !important;
      line-height: 1.25rem !important;
      padding-left: 0.5rem;
      color: rgba(107, 114, 128, 1) !important;
      margin-bottom: 0;
    }
  }
  label {
    font-weight: 600 !important;
    font-size: 1rem !important;
    line-height: 1.5rem !important;
    color: rgba(83, 89, 234, 1);
    position: absolute;
    bottom: 30px;
    right: 35px;
  }
}
.propertymainsection {
  max-width: 1280px;

  padding-bottom: 2rem;
  margin: auto;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 3rem;
    padding-bottom: 5rem;

    h6 {
      font-size: 1.875rem !important;
      line-height: 2.25rem !important;
      color: rgba(17, 24, 39, 1) !important;
      font-weight: 600;
    }
    span {
      color: rgba(83, 89, 234, 1);
    }
    label {
      color: rgba(255, 255, 255, 1) !important;
      width: 7rem;
      padding-top: 10px;
      padding-bottom: 10px;
      font-weight: 600 !important;
      background-color: rgba(83, 89, 234, 1);
      font-size: 0.875rem !important;
      line-height: 1.25rem !important;
      border-radius: 10px;
      text-align: center !important;
    }
  }
}
.react-multiple-carousel__arrow {
  box-shadow: 0 5px 10px 0 rgba(14, 14, 14, 0.16) !important;
}
.react-multiple-carousel__arrow::before {
  color: #000 !important;
}
.react-multiple-carousel__arrow:hover {
  color: #000 !important;
  background: #fff !important;
}
.react-multiple-carousel__arrow--right {
  right: calc(0% + 1px) !important;
}
.react-multiple-carousel__arrow--left {
  left: calc(0% + 1px) !important;
}
.react-multiple-carousel__arrow {
  background: #fff !important;

  cursor: pointer !important;
}

.rented {
  background-color: rgba(243, 244, 246, 1) !important;
  padding-top: 3rem;
  padding-bottom: 1rem;
  .overlap {
    background-color: #000;
    opacity: 0.5;
    width: 238px;
    height: 270px;
    top: 11px;
    position: absolute;
    border-radius: 10px;
  }
  .rentcarsole {
    width: 16rem;
    position: relative;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    padding: 10px;
    margin-top: 2rem;
    margin-bottom: 2rem;
    img {
      width: 100%;
      height: 271px;
      border-radius: 10px;
    }
    h3 {
      font-size: 0.875rem !important;
      line-height: 1.25rem !important;
      color: white;
      color: white;
      position: absolute;
      top: 20px;
      left: 22px;
      z-index: 1;
      svg {
        margin-right: 10px;
      }
    }
    h6 {
      color: rgba(17, 24, 39, 1) !important;
      margin-top: 10px;
      font-weight: 600 !important;
      text-align: center !important;
      font-size: 1.125rem !important;
    }
    h4 {
      color: rgba(17, 24, 39, 1) !important;

      font-weight: 500 !important;

      text-align: center !important;
      font-size: 14px;
    }
  }
  h1 {
    font-size: 1.875rem !important;
    line-height: 2.25rem !important;
    color: rgba(17, 24, 39, 1) !important;
    font-weight: 700 !important;
    text-align: center;
  }
  P {
    font-size: 0.875rem !important;
    line-height: 1.25rem !important;
    color: rgba(107, 114, 128, 1) !important;
    text-align: center;
  }
  .dropDownSearch {
    background-color: #fff;
    width: 39rem;
    padding-right: 14px;
    border-radius: 10px;
    padding-right: 14px;
    border-radius: 17px;
    margin: auto;
    margin-bottom: 2rem;
    margin-top: 1.5rem;
    .inputsection {
      display: flex;
      align-items: center;
      padding-top: 14px;
      padding-bottom: 14px;

      .money {
        font-size: 0.875rem !important;
        line-height: 1.25rem !important;
        display: flex;
        align-items: center;
        background-color: rgba(229, 231, 235, 1) !important;
        width: 12rem;

        height: 2.5rem;
        justify-content: center;
        border-radius: 10px;
        svg {
          margin-left: 12px !important;
        }
      }
      input {
        width: 8rem;
        height: 2.5rem;
        border: none;
        border-radius: 10px;
        background-color: white;
        width: 100%;
        font-size: 0.875rem !important;
        line-height: 1.25rem !important;
        padding-left: 1rem;
        outline: 0px solid transparent !important;
        outline-offset: 0px !important;
        color: rgba(229, 231, 235, 1) !important;
      }
      .seacrhIcon {
        background-color: #5259ea;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 12rem;

        height: 2.5rem;
        justify-content: center;
        border-radius: 10px;
        margin-left: 10px;
      }
    }
    .sub {
      display: flex;
      align-items: center;
      border-bottom: 0.5px solid #e5e7eb;
      height: 4rem;
      padding-left: 16px;
      font-weight: 500;
      h2 {
        font-weight: 300 !important;
        color: #000;
        font-size: 0.875rem !important;
        margin-right: 26px;
        margin-bottom: 0;
        line-height: 1.25rem !important;
      }
    }
  }
}
