.nav-menu {
  background-color: white;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 9999;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 0.5px solid #e7e7e7;
  .menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 58px;
  }
  .leftside {
    img {
      &:hover {
        transform: scale(1.1);
      }
    }
  }
  .right-side {
    display: flex;
    align-items: center;
  }
  h3 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    margin-right: 47px;
    margin-bottom: 0;
    color: #292929;
    &:hover {
      transform: scale(1.1);
    }
  }
  .login {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    width: 100px;
    height: 39px;
    background: #ededed;
    border: 1px solid #ededed;
    border-radius: 15px;
    color: #292929;
    margin-bottom: 0;
    &:hover {
      transform: scale(1.1);
    }
  }
  .register {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 800;
    font-size: 13px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    width: 116px;
    height: 39px;
    margin-left: 10px;
    justify-content: center;
    background: #4b5bb2;
    border: 0.5px solid #4b5bb2;
    border-radius: 15px;
    color: #ffffff;
    margin-bottom: 0;
    &:hover {
      transform: scale(1.1);
    }
  }
}
